.contactPage {
    margin-top: 4rem;
    width: 100%;
}

.contactContainer {
    display: flex;
}

.contactPage .companyTitle {
    padding: 3rem 10% 0;
    align-items: center;
}

.infoItemTitle,
.infoItemData {
    padding: 0 10%;
}

.infoItem {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 4rem;
}

.infoItem:last-child {
    margin-bottom: 2rem;
}

.infoItemTitle {
    font-size: 1.5rem;
    color: #2C3F9B;
    font-weight: 500;
}

.infoItemData {
    font-weight: 500;
}

.infoItemTitle,
.infoItemData {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.infoItemTitle svg,
.infoItemData svg {
    width: 1.5rem;
}

.infoItemTitle svg {
    fill: #2C3F9B;
}

.leftContact {
    width: 60%;
}

.rightContact {
    width: 40%;
    position: relative;
    clip-path: polygon(0% 100%, 100% 100%, 100% 0%, 15% 0%);
}

.rightContact img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 1024px) {
    .leftContact {
        width: 50%;
    }

    .rightContact {
        width: 50%;
        clip-path: polygon(0% 100%, 100% 100%, 100% 0%, 45% 0%);
    }
}

@media (max-width: 768px) {

    .infoItem {
        margin-top: 1.25rem;
        gap: .75rem;
    }

    .infoItem:last-child {
        margin-bottom: 6rem;
    }

    .infoItemTitle svg,
    .leftContact .titleSvg {
        width: 1.25rem;
        overflow: visible;
    }

    .leftContact .companyTitle {
        font-size: clamp(1rem, 5vw, 1.5rem);
    }

    .infoItemTitle {
        font-size: clamp(.875rem, 4vw, 1.25rem);
        white-space: nowrap;
        padding-right: 0;
    }

    .infoItemData {
        font-size: clamp(.75rem, 3vw, 1.25rem);
        width: min-content;
    }
}

@media (max-width: 500px) {

    .infoItemTitle svg,
    .leftContact .titleSvg {
        width: .875rem;
    }

    .infoItemData svg {
        width: .75rem;
    }

    .infoItemData {
        gap: .25rem;
    }
}