.gridCustomers {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    align-items: center;
    gap: 1rem 4rem;
}

.customer-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.customer-item img {
    width: 100%;
    object-fit: contain;
    aspect-ratio: 1;
}