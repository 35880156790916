.gridProyectos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
    gap: 4rem;
}

.proyect {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.proyectImage {
    width: 100%;
    object-fit: contain;
    height: max(300px, 30vh);
    transition: opacity 0.5s ease;
}

.hoverInfo {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(44, 63, 155);
    padding: 2rem;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0s 0.5s;
}

.proyect:hover .proyectImage {
    opacity: 0;
}

.proyect:hover .hoverInfo {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease, visibility 0s 0s;
}

.proyectTitle,
.proyectDetails,
.proyectLocation,
.proyectClient {
    margin: 0;
    font-size: 18px;
    text-align: center;
}

.proyectDetails,
.proyectLocation,
.proyectClient {
    font-size: 1rem;
    font-weight: 500;
}

.proyectTitle {
    font-size: 1.75rem;
    margin-bottom: 10px;
    position: relative;
    text-align: left;
}

.proyectTitle::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 1px;
    background-color: #fff;
}

.proyectDetails {
    height: 100%;
    font-size: 1.5rem;
    margin-top: 1rem;
    text-align: left;
}

.proyectLocation,
.proyectClient {
    font-size: 1.25rem;
}

.proyectClient {
    margin-top: .5rem;
}

.button-swd {
    border-radius: 1.25rem;
    border: 2px solid rgba(36, 56, 161);
    color: rgba(36, 56, 161);
    background: transparent;
    width: 50%;
    margin: auto;
    height: 3rem;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: all 0.5s ease, color 0.5s ease, fill 0.5s ease;
}

.button-swd.back svg {
    width: .75rem;
    height: .75rem;
    fill: rgba(36, 56, 161);
    transition: fill 0.5s ease;
}

.button-swd.back {
    margin: 0;
    width: 10%;
    height: 2rem;
}

.button-swd:hover {
    background: rgba(36, 56, 161);
    color: #fff;
}

.button-swd:hover svg {
    fill: #fff;
}

@media (max-width: 1024px) {
    .gridProyectos {
        grid-template-columns: 1fr !important;
        gap: 2rem !important;
    }

    .hoverInfo,
    .proyectImage {
        height: auto !important;
    }

    .proyectTitle {
        font-size: 1rem;
    }

    .proyectDetails,
    .proyectLocation,
    .proyectClient {
        font-size: .875rem;
    }

    .button-swd.back{
        width: min(40%,200px);
    }
}

@media (max-width: 768px) {
    .button-swd {
        width: 100%;
    }
}