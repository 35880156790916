.sliderImg {
    height: 100vh;
    width: 100vw;
}

.sliderOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background: linear-gradient(180deg, rgba(26, 33, 89, 0.7) 26%, rgba(0, 0, 0, 0.7) 63.5%);
}

.sliderImg img {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
}

.slick-arrow {
    z-index: 1;
    opacity: .5;
    transform: scale(1.5);
}

.slick-prev {
    left: 1rem;
}

.slick-next {
    right: 1rem;
}

.floatInfo {
    position: absolute;
    z-index: 1;
    position-area: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 3rem;
    /*La suma del gap y el font-size de abajo*/
}

.logoText {
    color: white;
    font-size: 2rem;
    text-align: center;
}

.logoImg {
    width: min(1240px, 50vw);
    object-fit: contain;
}

.sliderImg a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.proyectButton {
    position: absolute;
    cursor: pointer;
    top: 75%;
    z-index: 2;
    padding: 1rem;
    width: min(25rem, 70vw);
    border-radius: 1.25rem;
    border: 2px solid rgba(255, 255, 255, 0.60);
    background: transparent;
    color: white;
    transition: all .5s ease;
}

.proyectButton:hover {
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .floatInfo {
        position-area: none;
        margin-top: 20vh;
    }

    .slick-arrow {
        top: 60%;
    }

    .sliderImg {
        height: 75vh;
    }

    .sliderImg img {
        object-fit: cover;
    }

    .logoImg {
        width: min(400px, 90%);
    }

    .logoText {
        font-size: 1rem;
    }

    .proyectButton {
        top: calc(60% - 1rem);
    }
}