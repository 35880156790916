.footer {
    background: #F0F0F0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    color: rgba(0, 0, 0, 0.70);
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    position: relative;
}

.footer img {
    max-height: 2rem;
}

.footerElements {
    display: flex;
    justify-content: space-around;
    width: 90%;
    gap: 5rem;
}

.columnTitle {
    font-weight: 500;
}

.firstColumn img {
    width: min-content;
    object-fit: contain;
    max-height: 3rem;
}

.diff {
    width: min-content;
    border-radius: 1rem;
    padding: .5rem .5rem;
}

.firstColumn,
.secondColumn,
.thirdColumn {
    display: flex;
    flex-direction: column;
    width: calc((100% / 3));
    gap: 2rem;
    margin-top: 3rem;
}

.iconLinesCont {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    justify-content: center;
    align-items: flex-start;
}

.iconLine {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.socialCont {
    display: flex;
    gap: 1rem;
}

.devBy {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    font-size: .75rem;
}

.devBy img {
    top: 0.1rem;
    position: relative;
}

.secondColumn .socialCont {
    flex-direction: column;
}

.socialCont svg {
    width: 2rem;
    fill: rgba(0, 0, 0, 0.70);
}

.socialItem svg {
    width: 1rem;
}

.socialItem {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.float-button {
    width: 6vw;
    height: 6vw;
    max-width: 90px;
    max-height: 90px;
    min-width: 70px;
    min-height: 70px;
    border-radius: 50%;
    transition: all 0.5s ease;
    position: fixed;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 2rem;
    bottom: 2rem;
}

.float-button:hover {
    transform: scale(1.1);
}

.float-button.wp {
    background-color: #25d366;
}

@media (max-width:1024px) {
    .footerElements {
        flex-direction: column;
        gap: .5rem;
    }

    .firstColumn,
    .secondColumn,
    .thirdColumn {
        width: 100%;
        align-items: flex-start;
        text-align: left;
    }

    .devBy {
        justify-content: center;
        margin-top: 2rem;
    }

    .float-button {
        right: 1rem;
        bottom: 1rem;
    }
}