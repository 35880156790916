.dataFloatInfo {
    display: flex;
    padding: 3rem 2rem;
    justify-content: center;
    align-items: center;
    transform: translateY(-50%);
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    margin: auto;
    border-radius: 3rem;
}

.dFloatItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    position: relative;
    width: calc(100%/3);
}

.dFloatItem:not(:last-child):after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    width: 2px;
    height: 50%;
    background-color: #2C3F9B;
    transform: translateY(-50%);
}

.dFloatData {
    color: #2C3F9B;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
}

.dFloatText {
    text-align: center;
}

.section-information {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.companyTitle {
    font-size: 2rem;
    font-weight: 700;
    color: #2C3F9B;
    display: flex;
    gap: 1rem;
    align-items: flex-start;
}

.titleSvg {
    width: 2rem;
    fill: #2C3F9B;
}

.companyInfoSection {
    display: flex;
}

.companyText,
.companyImage {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.companyText span {
    width: 80%;
    font-size: 1.25rem;
}

.companyImage {
    justify-content: center;
    align-items: center;
}

.companyImage img {
    width: 75%;
}

.cardsContainer {
    display: flex;
    gap: 8rem;
    margin: 7rem 0 0;
}

.blueCard {
    background: #2C3F9B;
    color: #FFF;
    padding: 0 2rem;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}

.cardImage {
    border-radius: 100%;
    background: #2C3F9B;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    transform: translateY(-50%);
}

.cardImage img {
    width: 60%;
    aspect-ratio: 1;
    object-fit: contain;
}

.blueCard span {
    position: relative;
    padding: 0 1rem;
    width: 100%;
    text-align: center;
    margin: -2rem 0 0;
}

.blueCard span::before,
.blueCard span::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 40%;
    height: 1px;
    background-color: #fff;
    transform: translateY(-50%);
}

.blueCard span::before {
    left: 0;
}

.blueCard span::after {
    right: 0;
}

.blueCard p {
    text-align: center;
    line-height: 2rem;
}

@media (max-width:1024px) {
    .dataFloatInfo {
        flex-direction: column;
        width: 75%;
        transform: translateY(-25%);
        gap: 2rem;
    }

    .dFloatItem:not(:last-child):after {
        top: 50%;
        left: 50%;
        transform: rotate(90deg);
        height: 100%;
        margin-top: 1rem;
        width: 1px;
    }

    .dFloatItem {
        width: 100%;
        gap: 1rem;
    }

    .companyInfoSection,
    .cardsContainer {
        flex-direction: column;
        gap: 4rem;
    }

    .cardsContainer {
        gap: 8rem;
    }

    .companyText,
    .companyImage,
    .companyImage img {
        width: 100%;
    }

    .blueCard {
        width: auto;
    }

    .companyTitle {
        font-size: 1.5rem;
    }

    .companyText span {
        width: 100%;
    }

    .gridCustomers {
        grid-template-columns: repeat(3, 1fr) !important;
        gap: 2rem !important;
    }
}