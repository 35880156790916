.imageDetail {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.imageDetail img {
    width: 100%;
}

.imageDetail span {
    font-size: .875rem;
    color: #333;
    font-style: italic;
}

.description p {
    line-height: 2rem;
}

.stepsContainer {
    width: 100%;
    display: flex;
}

.stepInfo {
    width: 45%;
}

.stepNumber {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    font-weight: bold;
    color: #2C3F9B;
}

.stepInfo img {
    width: 100%
}

.stepInfo p {
    font-weight: 500;
}

.detailSliderImg {
    object-fit: contain;
    width: 100%;
    height: 400px;
}

.detailSliderCont {
    width: 100%;
    padding: 2rem 0;
    border: 1px solid #2C3F9B;
    border-width: 1px 0;
}

.carrouselDetail button::before {
    color: #2C3F9B;
}

@media (max-width: 768px) {
    .stepInfo {
        width: 40%;
    }

    .stepNumber {
        width: 20%;
        font-size: 2rem;
    }

    .stepInfo p{
        font-size: .875rem;
    }
}